import { Global, css } from "@emotion/core"
import React from 'react'
import noise from "../utils/noise"
import { encodeSVG } from "../utils/encodeSVG"
import starburst from "../images/starburst.svg"
import starburstDark from "../images/starburst-dark.svg"

const GlobalStyle = () => <Global 
    styles={css`
        body {
            transition: color 250ms ease-in-out,
                background-color 250ms ease-in-out;
        }

        a {
            color: var(--accent);
        }

        body {
            background-color: var(--bg);
            color: var(--color);
        }

        body.light {
            --bg: white;
            --bgDark: #f1f1f1;
            --bgAccent: #FCD687;
            --bgHoverAccent: #121212;
            --bgDarkHover: black;
            --btnColor: #333;
            --btnBgColor: #C3609B;
            --btnHoverColor: #eee;
            --btnHoverBgColor: #a04c79;
            --btnBorderColor: #1a1a1a;
            --borderColor: #000;
            --rgb: 0;
            --a: 2;
            --color: #333;
            --accent: rgb(255, 212, 42);
            --hoverAccent: #ff6d4e;
            --dropDownLinks: #444;
            --linkColor: rebeccapurple;
            --linkHoverColor: #61dafb;
            --navLinks: #000;
            --navLinksHover: #fff;
            --blockquoteHighlight: #333;
            --postTitle: #000;
            --postTitleHover: rgb(255, 212, 42);
            --sharing: #f1f1f1;
            --tagsList: #000;
            --tagColor: #fff;
            --tagBorder: #000;
            --purple: rebeccapurple;
            --blue: #61dafb;
            --starburst: url(${encodeSVG(starburst)});

            div.featured-img-container:after {
                background-image: url(${noise}) !important;
                background-repeat: repeat !important;
                opacity: 0.5 !important;
                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)" !important;
            }
            div.featured-img-container:hover:after {
                opacity: 0 !important;
                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)" !important;
            }
        }

        body.dark {
            -webkit-font-smoothing: antialiased;
            --bg: #121212;
            --bgDark: #222;
            --bgAccent: #d1c7b2;
            --bgHoverAccent: #efefef;
            --bgDarkHover: grey;
            --btnColor: #eee;
            --btnBgColor: #aa7996;
            --btnHoverColor: #333;
            --btnHoverBgColor: #8e5e78;
            --btnBorderColor: #f0f0f0;
            --borderColor: #919191;
            --rgb: 255;
            --a: 1;
            --color: #eee;
            --accent: #d6bc53;
            --hoverAccent: #c2958b;
            --linkColor: #9783ab;
            --dropDownLinks: #121212;
            --navLinks: #fff;
            --navLinksHover: #000;
            --blockquoteHighlight: #919191;
            --postTitle: #f0f0f0;
            --postTitleHover: #99bac3;
            --sharing: #444;
            --tagsList: #343434;
            --purple: #66527a;
            --blue: #99bac3;
            --tagColor: #2a2a2a;
            --tagBorder: #2a2a2a;
            --starburst: url(${encodeSVG(starburstDark)});

            .gatsby-image-wrapper:after, 
            .gatsby-resp-image-wrapper:after {
                position: absolute;
                content: "";
                display: block;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background: rgba(0,0,0,.20);
            }

            div.featured-img-container:after {
                background: rgba(0,0,0,.35) !important;
            }
        }

        h1, h2, h3, h4, h5, h6 {
            color: var(--color);
            transition: color 250ms ease-in-out;
        }

        h1 > a {
            text-decoration: none;
        }

        h3 {
          margin-bottom: 5px;
          &:after {
            content: "";
            height: 5px;
            width: 100%;
            background: linear-gradient(to left, var(--blue), var(--purple), var(--blue), var(--purple), var(--blue), var(--purple));
            display: block;
            margin-bottom: 30px;
          }
        }

        a {
            color: var(--linkColor);
            transition: color 250ms ease-in-out,
            background-color 250ms ease-in-out;
          &:hover {
            color: var(--blue);
          }
        }
        a:active,
        a:hover {
          outline-width: 0;
        }
        nav > a {
          transition: none !important;
        }

        blockquote {
          margin-left: 1.5rem;
          margin-right: 1.5rem;
          margin-top: 0;
          padding-bottom: 0;
          padding-left: 0;
          padding-right: 0;
          padding-top: 0;
          margin-bottom: 1.5rem;
          position: relative;
          quotes: "“" "”" "‘" "’";
          font-style: italic;
        }
        blockquote:before {
          position: absolute;
          left: calc(-1.5rem + 2px);
          width: 2px;
          content: "";
          height: 100%;
          background: var(--blockquoteHighlight);
          transition: color 250ms ease-in-out,
            background-color 250ms ease-in-out;
        }
        blockquote:after {
          position: absolute;
          content: "";
          height: 100%;
          width: 0;
          top: 0;
          left: calc(-1.5rem + 4px);
          border-left: 0.8rem solid var(--blockquoteHighlight);
          border-right: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-top: 5px solid transparent;
          transition: color 250ms ease-in-out,
            border-color 250ms ease-in-out;
        }
    `}
/>

export default GlobalStyle